import { ReactNode, HTMLAttributes, forwardRef } from 'react';
import styles from './Text.module.scss';
import classNames from 'classnames';

export type TextTheme =
  | 'greyColor100'
  | 'greyColor200'
  | 'greyColor300'
  | 'greyColor400'
  | 'greyColor500'
  | 'greyColor600'
  | 'greyColor700'
  | 'greenColor100'
  | 'greenColor200'
  | 'blueColor100'
  | 'purpleColor100'
  | 'primary'
  | 'secondary'
  | 'primaryInverted'
  | 'tertiary'
  | 'quaternary'
  | 'fifthColor'
  | 'error'
  | 'clearError';

export type TextSize =
  | 'inherit'
  | 'sizeXS'
  | 'sizeS'
  | 'size18px'
  | 'size12px'
  | 'size14px'
  | 'size20px'
  | 'sizeM'
  | 'sizeL'
  | 'sizeXL';

export type TextLineHeight = '1' | '1.2' | '1.3' | '1.4' | '1.5';
export type TextWeight = '300' | '400' | '500' | '600' | '700';
export type TextDecoration = 'underline';
export type TextTransform = 'uppercase';
export type TextStyle = 'italic';
export type TextAlign = 'left' | 'right' | 'center';

const textAlignClasses: Record<TextAlign, string> = {
  center: styles.textAlignCenter,
  left: styles.textAlignLeft,
  right: styles.textAlignRight
};

const textLineHeightClasses: Record<TextLineHeight, string> = {
  1: styles.lineHeightOne,
  1.2: styles.lineHeightOnePointTwo,
  1.3: styles.lineHeightOnePointThree,
  1.4: styles.lineHeightOnePointFour,
  1.5: styles.lineHeightOnePointFive
};

const themeClasses: Record<TextTheme, string> = {
  greyColor100: styles.greyColor100,
  greyColor200: styles.greyColor200,
  greyColor300: styles.greyColor300,
  greyColor400: styles.greyColor400,
  greyColor500: styles.greyColor500,
  greyColor600: styles.greyColor600,
  greyColor700: styles.greyColor700,
  greenColor100: styles.greenColor100,
  greenColor200: styles.greenColor200,
  blueColor100: styles.blueColor100,
  purpleColor100: styles.purpleColor100,
  primary: styles.primary,
  secondary: styles.secondary,
  primaryInverted: styles.primaryInverted,
  tertiary: styles.tertiary,
  quaternary: styles.quaternary,
  fifthColor: styles.fifthColor,
  error: styles.error,
  clearError: styles.clearError
};

const weightClasses: Record<TextWeight, string> = {
  300: styles.weight300,
  400: styles.weight400,
  500: styles.weight500,
  600: styles.weight600,
  700: styles.weight700
};

const sizeClasses: Record<TextSize, string> = {
  inherit: styles.fontSizeInherit,
  sizeXS: styles.sizeXS,
  sizeS: styles.sizeS,
  sizeM: styles.sizeM,
  size18px: styles.size18px,
  size20px: styles.size20px,
  size12px: styles.size12px,
  size14px: styles.size14px,
  sizeL: styles.sizeL,
  sizeXL: styles.sizeXL
};

const decorationClasses: Record<TextDecoration, string> = {
  underline: styles.underline
};

const transformClasses: Record<TextTransform, string> = {
  uppercase: styles.uppercase
};

const textStyleClasses: Record<TextStyle, string> = {
  italic: styles.italic
};

interface TextProps extends HTMLAttributes<HTMLParagraphElement> {
  className?: string;
  children?: ReactNode;
  theme?: TextTheme;
  size?: TextSize;
  weight?: TextWeight;
  decoration?: TextDecoration;
  transform?: TextTransform;
  textStyle?: TextStyle;
  textAlign?: TextAlign;
  lineHeight?: TextLineHeight;
}

const Text = forwardRef<HTMLParagraphElement, TextProps>((props, ref) => {
  const {
    children,
    className,
    theme,
    size = 'sizeM',
    weight = '400',
    decoration,
    transform,
    textStyle,
    textAlign,
    lineHeight,
    ...otherProps
  } = props;

  const classes = [
    className,
    theme && themeClasses[theme],
    sizeClasses[size],
    weightClasses[weight],
    decoration && decorationClasses[decoration],
    transform && transformClasses[transform],
    textStyle && textStyleClasses[textStyle],
    textAlign && textAlignClasses[textAlign],
    lineHeight && textLineHeightClasses[lineHeight]
  ];

  return (
    <p className={classNames(styles.text, {}, classes)} ref={ref} {...otherProps}>
      {children}
    </p>
  );
});

Text.displayName = 'Text';

export { Text };
