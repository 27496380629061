import { forwardRef, ButtonHTMLAttributes } from 'react';
import styles from './Button.module.scss';
import classNames from 'classnames';

export type ButtonTheme =
  | 'clear'
  | 'clearGrey'
  | 'clearGrey100'
  | 'primary'
  | 'fourthlyBgColor'
  | 'primaryWithoutHover'
  | 'secondary'
  | 'third'
  | 'thirdWithoutBg'
  | 'clearPrimary'
  | 'tabPrimary'
  | 'tabActivePrimary'
  | 'tabSecondary'
  | 'tabActiveSecondary'
  | 'fourthly'
  | 'reject';

export type ButtonSize = 'sizeXS' | 'sizeS' | 'sizeM' | 'sizeL' | 'sizeXL';
export type ButtonWeight = '300' | '400' | '500' | '600' | '700';
export type ButtonBoxShadow = 'box-shadow100' | 'box-shadow200';
export type ButtonRadius = '4px' | '8px';
export type ButtonTransform = 'uppercase';
export type ButtonDecoration = 'underline';
export type ButtonTextStyle = 'italic';
export type ButtonTextAlign = 'left' | 'right' | 'center';
export type ButtonLineHeight = '1' | '1.2' | '1.3' | '1.4' | '1.5';

const buttonLineHeightClasses: Record<ButtonLineHeight, string> = {
  1: styles.lineHeightOne,
  1.2: styles.lineHeightOnePointTwo,
  1.3: styles.lineHeightOnePointThree,
  1.4: styles.lineHeightOnePointFour,
  1.5: styles.lineHeightOnePointFive
};

const textAlignClasses: Record<ButtonTextAlign, string> = {
  center: styles.textAlignCenter,
  left: styles.textAlignLeft,
  right: styles.textAlignRight
};

const themeClasses: Record<ButtonTheme, string> = {
  clear: styles.clear,
  clearGrey: styles.clearGrey,
  clearGrey100: styles.clearGrey100,
  clearPrimary: styles.clearPrimary,
  primary: styles.primary,
  fourthlyBgColor: styles.fourthlyBgColor,
  primaryWithoutHover: styles.primaryWithoutHover,
  secondary: styles.secondary,
  third: styles.third,
  thirdWithoutBg: styles.thirdWithoutBg,
  tabActivePrimary: styles.tabActivePrimary,
  tabPrimary: styles.tabPrimary,
  tabActiveSecondary: styles.tabActiveSecondary,
  tabSecondary: styles.tabSecondary,
  fourthly: styles.fourthly,
  reject: styles.reject
};

const activeThemeClasses: Record<string, string> = {
  tabPrimary: styles.tabActivePrimary
};

const weightClasses: Record<ButtonWeight, string> = {
  300: styles.weight300,
  400: styles.weight400,
  500: styles.weight500,
  600: styles.weight600,
  700: styles.weight700
};

const sizeClasses: Record<ButtonSize, string> = {
  sizeXS: styles.sizeXS,
  sizeS: styles.sizeS,
  sizeM: styles.sizeM,
  sizeL: styles.sizeL,
  sizeXL: styles.sizeXL
};

const boxShadowClasses: Record<ButtonBoxShadow, string> = {
  'box-shadow100': styles.boxShadow100,
  'box-shadow200': styles.boxShadow200
};

const radiusClasses: Record<ButtonRadius, string> = {
  '4px': styles.br4px,
  '8px': styles.br8px
};

const transformClasses: Record<ButtonTransform, string> = {
  uppercase: styles.uppercase
};

const decorationClasses: Record<ButtonDecoration, string> = {
  underline: styles.underline
};

const buttonTextStyleClasses: Record<ButtonTextStyle, string> = {
  italic: styles.italic
};

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  theme?: ButtonTheme;
  size?: ButtonSize;
  weight?: ButtonWeight;
  boxShadow?: ButtonBoxShadow;
  square?: boolean;
  radius?: ButtonRadius;
  transform?: ButtonTransform;
  decoration?: ButtonDecoration;
  textStyle?: ButtonTextStyle;
  textAlign?: ButtonTextAlign;
  active?: boolean;
  lineHeight?: ButtonLineHeight;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    children,
    className,
    theme = 'clear',
    size = 'sizeM',
    weight = '400',
    square = false,
    lineHeight = '1',
    boxShadow,
    radius,
    transform,
    decoration,
    textStyle,
    textAlign,
    active,
    ...otherProps
  } = props;

  const classes = [
    className,
    themeClasses[theme],
    weightClasses[weight],
    sizeClasses[size],
    boxShadow && boxShadowClasses[boxShadow],
    radius && radiusClasses[radius],
    transform && transformClasses[transform],
    decoration && decorationClasses[decoration],
    textStyle && buttonTextStyleClasses[textStyle],
    textAlign && textAlignClasses[textAlign],
    active && theme && activeThemeClasses[theme],
    lineHeight && buttonLineHeightClasses[lineHeight]
  ];

  const mods = {
    [styles.square]: square
  };

  return (
    <button className={classNames(styles.button, mods, classes)} ref={ref} {...otherProps}>
      {children}
    </button>
  );
});

Button.displayName = 'Button';

export { Button };
