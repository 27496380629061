import { FC, useCallback, useEffect, useState } from 'react';
import styles from './Alert.module.scss';
import classNames from 'classnames';
import { Text } from 'shared/ui/Text/Text';
import { TAlert } from '../../model/types/alert';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { alertActions } from 'entities/Alert/model/slices/alert';

export interface AlertProps {
  alert: TAlert;
}

const Alert: FC<AlertProps> = (props) => {
  const { alert } = props;

  const [showAlert, setShowAlert] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const timerId = setTimeout(() => {
      setShowAlert(true);
    }, 10);

    return () => {
      clearTimeout(timerId);
    };
  }, []);

  const handleClose = useCallback(() => {
    setShowAlert(false);
    setTimeout(() => {
      dispatch(alertActions.removeAlert());
    }, 200);
  }, []);

  useEffect(() => {
    if (!alert.infinite) {
      const timerId = setTimeout(() => {
        handleClose();
      }, alert.timeout);

      return () => {
        clearTimeout(timerId);
      };
    }
  }, []);

  const mods = {
    [styles[alert.type]]: true,
    [styles.open]: showAlert,
    [styles.closed]: !showAlert
  };

  return (
    <div className={classNames(styles.alert, mods, styles[alert.type])} onClick={handleClose}>
      <Text
        size="sizeM"
        weight="500"
        className={styles.alertText}
        dangerouslySetInnerHTML={{ __html: alert.message }}
      ></Text>
    </div>
  );
};

export { Alert };
