import { AnchorHTMLAttributes, FC, ReactNode } from 'react';
import styles from './Link.module.scss';
import classNames from 'classnames';

export type LinkTheme = 'primary' | 'primaryBg' | 'secondary' | 'greenColor100';
export type LinkSize = 'sizeXS' | 'sizeS' | 'sizeM' | 'sizeL' | 'sizeXL';
export type LinkWeight = '300' | '400' | '500' | '600' | '700';
export type LinkDecoration = 'none' | 'underline';
export type LinkTransform = 'uppercase';

const themeClasses: Record<LinkTheme, string> = {
  primary: styles.primary,
  primaryBg: styles.primaryBg,
  secondary: styles.secondary,
  greenColor100: styles.greenColor100
};

const textDecorationClasses: Record<LinkDecoration, string> = {
  none: styles.decorationNone,
  underline: styles.underline
};

const textTransformClasses: Record<LinkTransform, string> = {
  uppercase: styles.uppercase
};

const weightClasses: Record<LinkWeight, string> = {
  300: styles.weight300,
  400: styles.weight400,
  500: styles.weight500,
  600: styles.weight600,
  700: styles.weight700
};

const sizeClasses: Record<LinkSize, string> = {
  sizeXS: styles.sizeXS,
  sizeS: styles.sizeS,
  sizeM: styles.sizeM,
  sizeL: styles.sizeL,
  sizeXL: styles.sizeXL
};

interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  className?: string;
  children?: ReactNode;
  size?: LinkSize;
  weight?: LinkWeight;
  theme?: LinkTheme;
  decoration?: LinkDecoration;
  transform?: LinkTransform;
}

const Link: FC<LinkProps> = (props) => {
  const {
    children,
    className,
    size = 'sizeS',
    weight = '400',
    theme,
    decoration = 'underline',
    transform,
    ...otherProps
  } = props;

  const classes = [
    className,
    theme && themeClasses[theme],
    sizeClasses[size],
    weightClasses[weight],
    textDecorationClasses[decoration],
    transform && textTransformClasses[transform]
  ];

  return (
    <a target="_blank" rel="noreferrer" className={classNames(styles.link, {}, classes)} {...otherProps}>
      {children}
    </a>
  );
};

export { Link };
