import { GetAllAvailableTokensResponse } from '../types/balance';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { MyAxiosError } from 'shared/types/axios';

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const getAllAvailableTokens = createAsyncThunk<
GetAllAvailableTokensResponse,
undefined,
ThunkConfig<MyAxiosError<Error>>
>('balance/getAllAvailableTokens', async (_, { rejectWithValue, extra }) => {
  try {
    const response = await extra.api.post<GetAllAvailableTokensResponse>(
      '/api/currencies',
      {
        merchant: {
          key: 'dcba45f9-2ad3-4e66-acf9-e89c1169b90c'
        }
      },
      {
        headers: {
          Accept: 'application/json'
        }
      }
    );

    if (!response.data) {
      throw new Error();
    }

    return response.data;
  } catch (e) {
    const error = e as MyAxiosError<Error>;
    error.message = 'The waiting time for the request to receive your balance has been exceeded';
    return rejectWithValue(error);
  }
});
