/* eslint-disable @typescript-eslint/no-unused-vars */
import { CombinedState, configureStore, getDefaultMiddleware, Reducer, ReducersMapObject } from '@reduxjs/toolkit';
import { StateSchema } from './StateSchema';
import { createReducerManager } from './reducerManager';
import { $apiWithRetryRequest } from 'shared/api/api';
import { errorMiddleware } from '../middlewares/errorMiddleware';
import { alertReducer } from 'entities/Alert';
import { balanceReducer } from 'entities/Balance/model/slices/balance';
import { $xamaxApiWithAuth } from 'shared/api/axiosWithAuth';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const createReduxStore = (initialState?: StateSchema, asyncReducers?: ReducersMapObject<StateSchema>) => {
  const rootReducer: ReducersMapObject<StateSchema> = {
    ...asyncReducers,
    alert: alertReducer,
    balance: balanceReducer
  };

  const reducerManager = createReducerManager(rootReducer);

  const store = configureStore({
    reducer: reducerManager.reduce as Reducer<CombinedState<StateSchema>>,
    devTools: import.meta.env.VITE_VERCEL_ENV === 'development',
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: {
            api: $apiWithRetryRequest,
            xamaxApi: $xamaxApiWithAuth
          }
        }
      }).concat(errorMiddleware())
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment, @typescript-eslint/prefer-ts-expect-error
  // @ts-ignore
  store.reducerManager = reducerManager;

  return store;
};

export type AppDispatch = ReturnType<typeof createReduxStore>['dispatch'];
