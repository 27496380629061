/* eslint-disable @typescript-eslint/no-unsafe-argument */
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from 'app/App';
import { StoreProvider } from 'app/providers/StoreProvider';
import { Web3ContextProvider } from 'app/providers/Web3Provider';
import { AvailableCurrenciesProvider } from 'app/providers/AvailableCurrenciesProvider';
import './utils';
import { OnboardingProvider } from 'app/providers/OnboardingProvider';
import { HeaderProvider } from 'app/providers/HeaderProvider';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <BrowserRouter>
    <StoreProvider>
      <Web3ContextProvider>
        <AvailableCurrenciesProvider>
          <HeaderProvider>
            <OnboardingProvider>
              <App />
            </OnboardingProvider>
          </HeaderProvider>
        </AvailableCurrenciesProvider>
      </Web3ContextProvider>
    </StoreProvider>
  </BrowserRouter>
);
