import { createSlice } from '@reduxjs/toolkit';
import { BalanceSchema } from '../types/balance';
import { getAllAvailableTokens } from '../services/getAllAvailableTokens';

const initialState: BalanceSchema = {
  error: undefined,
  data: undefined,
  normalBalance: undefined,
  isLoading: false
};

export const balanceSlice = createSlice({
  name: 'balance',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllAvailableTokens.pending, (state) => {
        state.error = undefined;
        state.isLoading = true;
      })
      .addCase(getAllAvailableTokens.fulfilled, (state, action) => {
        state.error = undefined;
        state.availableTokens = Object.values(action.payload).filter(
          (item) => !['bnb:bsc', 'bsc', 'busd:bsc', 'bscusd:bsc'].includes(item.value)
        );
        state.isLoading = false;
      })
      .addCase(getAllAvailableTokens.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      });
  }
});

export const { actions: balanceActions } = balanceSlice;
export const { reducer: balanceReducer } = balanceSlice;
