import React, { FC, useContext, useEffect } from 'react';
import styles from './AppLayout.module.scss';
import classNames from 'classnames';
import { Navbar } from 'widgets/Navbar';
import { HeaderProviderContext } from 'app/providers/HeaderProvider';
import { OnboardingContext } from 'app/providers/OnboardingProvider';

interface AppLayoutProps {
  children?: React.ReactNode;
  title?: string;
}

const AppLayout: FC<AppLayoutProps> = ({ children, title }) => {
  const { isSticky } = useContext(HeaderProviderContext);

  const { showHints } = useContext(OnboardingContext);

  useEffect(() => {
    if (title) {
      document.title = title;
    } else {
      document.title = '';
    }
  }, [title]);

  const mods = {
    // [styles.isSticky]: isSticky || showHints
  };

  return (
    <div className={styles.appLayout} style={{ overflow: showHints ? 'hidden' : 'unset' }}>
      <div className={classNames(styles.wrapper, mods)}>
        <Navbar />
        <main className={styles.main}>
          <section className={styles.content}>{children}</section>
        </main>
      </div>
    </div>
  );
};

export { AppLayout };
