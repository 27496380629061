import { Suspense, useEffect } from 'react';
import classNames from 'classnames';
import { AppRouter } from './providers/router';
import 'app/styles/main.scss';
import { AlertContainer } from 'entities/Alert';
import { getAllAvailableTokens } from 'entities/Balance';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';

const App = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllAvailableTokens());
  }, [dispatch]);

  return (
    <div className={classNames('app', {}, [])}>
      <Suspense fallback="loading">
        <AppRouter />
      </Suspense>
      <AlertContainer />
    </div>
  );
};

export default App;
