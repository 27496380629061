import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

import { baseUrl } from 'shared/constants/apiUrl/apiUrl';

interface TimeoutError extends Error {
  status: number;
}

export const axiosWithRetryRequestInstance = (url?: string): AxiosInstance => {
  const axiosInstance = axios.create({
    baseURL: url ?? baseUrl
  });

  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    async (error: AxiosError) => {
      if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
        console.log('Request timeout exceeded');
        const timeoutError = new Error('Request timeout exceeded') as TimeoutError;
        timeoutError.status = 408;
        return await Promise.reject(timeoutError);
      }

      return await Promise.reject(error);
    }
  );

  return axiosInstance;
};

export const $apiWithRetryRequest = axiosWithRetryRequestInstance();
