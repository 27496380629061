import { FC } from 'react';
import styles from './Logo.module.scss';
import classNames from 'classnames';
import logo from 'app/assets/images/logo.png';

interface LogoProps {
  className?: string;
}

const Logo: FC<LogoProps> = (props) => {
  const { className } = props;

  return <img src={logo} alt="Logo" className={classNames(styles.logo, {}, [className])} />;
};

export { Logo };
