import { useEffect, useContext } from 'react';
import styles from './Navbar.module.scss';
import classNames from 'classnames';
import { Logo } from 'shared/ui/Logo/Logo';
import { Link } from 'shared/ui/Link/Link';
import { Button } from 'shared/ui/Button/Button';
import { OnboardingContext } from 'app/providers/OnboardingProvider';
import { HeaderProviderContext } from 'app/providers/HeaderProvider';

const Navbar = () => {
  const { isSticky, setIsSticky } = useContext(HeaderProviderContext);
  const { setShowHints, showHints } = useContext(OnboardingContext);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky?.(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const mods = {
    // [styles.sticky]: isSticky || showHints
  };

  return (
    <header className={classNames(styles.navbar, mods, [])}>
      <Link href="https://xamax.io/" className={styles.linkLogo}>
        <Logo className={styles.logo} />
      </Link>
      <div className={styles.wrapper}>
        <Link href="https://my.xamax.io/" decoration="none" theme="primaryBg" size="sizeS" className={styles.signUpBtn}>
          SIGN UP
        </Link>
        <Button type="button" theme="clearPrimary" onClick={() => setShowHints?.((prev) => !prev)} size="sizeS">
          {showHints ? 'Hide hints' : 'Show hints'}
        </Button>
      </div>
    </header>
  );
};

export { Navbar };
