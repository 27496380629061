import { memo, Suspense, useCallback } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AppRoutesProps, routeConfig } from 'shared/config/routeConfig/routeConfig';
import { AppLayout } from 'shared/ui/AppLayout/AppLayout';
import { Layout } from 'shared/ui/Layout/Layout';
import { Loader } from 'widgets/Loader/ui/Loader';

const AppRouter = memo(() => {
  const renderWithWrapper = useCallback((route: AppRoutesProps) => {
    const { path, element, withLayout, nestedRoutes, title } = route;

    return (
      <Route
        key={path}
        path={path}
        element={withLayout ? <AppLayout title={title}>{element}</AppLayout> : <Layout>{element}</Layout>}
      >
        {nestedRoutes?.map(({ path, element }) => {
          return <Route path={path} key={path} element={element} />;
        })}
      </Route>
    );
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <Routes>{Object.values(routeConfig).map(renderWithWrapper)}</Routes>
    </Suspense>
  );
});

export { AppRouter };
