import React, { FC, useMemo, useState } from 'react';
import { HeaderProviderContext } from '../lib/HeaderProviderContext';

interface HeaderProviderProps {
  children?: React.ReactNode;
}

const HeaderProvider: FC<HeaderProviderProps> = ({ children }) => {
  const [isSticky, setIsSticky] = useState(false);

  const defaultProps = useMemo(() => ({ isSticky, setIsSticky }), [isSticky]);

  return <HeaderProviderContext.Provider value={defaultProps}>{children}</HeaderProviderContext.Provider>;
};

export default HeaderProvider;
