import React, { HTMLAttributes, memo } from 'react';
import styles from './Icon.module.scss';
import classNames from 'classnames';

interface IconProps extends HTMLAttributes<HTMLOrSVGElement> {
  className?: string;
  SVG: React.VFC<React.SVGProps<SVGSVGElement>>;
}

const Icon = memo(({ className, SVG, ...otherProps }: IconProps) => {
  return <SVG className={classNames(styles.icon, {}, className)} {...otherProps} />;
});

export { Icon };
