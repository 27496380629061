import { RouteProps } from 'react-router-dom';
import { CommerceDemo } from 'pages/CommerceDemo';
import { NotFoundPage } from 'pages/NotFoundPage';
import { CartPage } from 'pages/Cart';
import { SelectDemoPage } from 'pages/SelectDemoPage';
import { DepositDemo } from 'pages/DepositDemo';
import { CasinoDemo } from 'pages/CasinoDemo';

export type AppRoutesProps = RouteProps & {
  authOnly?: boolean;
  withLayout?: boolean;
  title?: string;
  nestedRoutes?: RouteProps[];
};

export enum AppRoutes {
  SELECT_DEMO = 'select-demo',
  COMMERCE = 'commerce',
  DEPOSIT = 'deposit',
  CASINO = 'casino',
  CART = 'cart',
  NOT_FOUND = 'not-found'
}

export const RoutePath: Record<AppRoutes, string> = {
  [AppRoutes.SELECT_DEMO]: '/',
  [AppRoutes.COMMERCE]: '/commerce',
  [AppRoutes.CASINO]: '/casino',
  [AppRoutes.DEPOSIT]: '/deposit/*',
  [AppRoutes.CART]: '/cart',
  [AppRoutes.NOT_FOUND]: '*'
};

export const routeConfig: Record<AppRoutes, AppRoutesProps> = {
  [AppRoutes.SELECT_DEMO]: {
    path: RoutePath['select-demo'],
    element: <SelectDemoPage />,
    withLayout: true,
    title: 'Select Demo'
  },
  [AppRoutes.COMMERCE]: {
    path: RoutePath.commerce,
    element: <CommerceDemo />,
    withLayout: true,
    title: 'Commerce'
  },
  [AppRoutes.DEPOSIT]: {
    path: RoutePath.deposit,
    element: <DepositDemo />,
    withLayout: true,
    title: 'Deposit'
  },
  [AppRoutes.CASINO]: {
    path: RoutePath.casino,
    element: <CasinoDemo />,
    withLayout: true,
    title: 'Casino'
  },
  [AppRoutes.CART]: {
    path: RoutePath.cart,
    element: <CartPage />,
    withLayout: true,
    title: 'Cart'
  },
  [AppRoutes.NOT_FOUND]: {
    path: RoutePath['not-found'],
    element: <NotFoundPage />,
    withLayout: false
  }
};
