import React, { FC, useMemo, useState } from 'react';
import { OnboardingContext } from '../lib/OnboardingContext';

interface OnboardingProviderProps {
  children?: React.ReactNode;
}

const OnboardingProvider: FC<OnboardingProviderProps> = ({ children }) => {
  const [showHints, setShowHints] = useState<boolean>(true);

  const defaultProps = useMemo(
    () => ({
      showHints,
      setShowHints
    }),
    [showHints]
  );

  return <OnboardingContext.Provider value={defaultProps}>{children}</OnboardingContext.Provider>;
};

export default OnboardingProvider;
