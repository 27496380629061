import { getAlertData } from 'entities/Alert/model/selectors/alert';
import React, { FC, memo } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { Alert } from '../Alert/Alert';

interface AlertContainerProps {}

const alertRoot = document.getElementById('alert-root');

const AlertContainer: FC<AlertContainerProps> = memo(() => {
  const alerts = useSelector(getAlertData);

  return (
    <>
      {ReactDOM.createPortal(
        alerts.map((alert) => <Alert key={alert.id} alert={alert} />),
        alertRoot!
      )}
    </>
  );
});

export { AlertContainer };
