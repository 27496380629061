import React, { FC, useMemo } from 'react';
import { AvailableCurrenciesContext } from '../lib/AvailableCurrenciesContext';
import { useSelector } from 'react-redux';
import { getAllAvailableTokensData } from 'entities/Balance';
import { SELECT_CRYPTO_CODES } from 'shared/constants/crypto/crypto-names';

interface AvailableCurrenciesProviderProps {
  children?: React.ReactNode;
}

const AvailableCurrenciesProvider: FC<AvailableCurrenciesProviderProps> = ({ children }) => {
  const availableTokens = useSelector(getAllAvailableTokensData);

  const defaultProps = useMemo(() => [], []);

  return <AvailableCurrenciesContext.Provider value={defaultProps}>{children}</AvailableCurrenciesContext.Provider>;
};

export default AvailableCurrenciesProvider;
